<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-subtitle
        >{{ title }}
        <span v-if="showVisibility"
          >(sichtbar für <LookupValue :value="visibility" />)</span
        ></v-list-item-subtitle
      >
      <slot></slot>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import { defineComponent } from "vue";
import LookupValue from "common/components/LookupValue.vue";

export default defineComponent({
  name: "RowItem",
  components: { LookupValue },
  props: {
    showVisibility: { type: Boolean, default: false },
    title: { type: String },
    visibility: { type: Object, default: null },
  },
});
</script>
